import React from 'react';

const TrimesterChart = () => {
  return (
    <section className="p-8 bg-gradient-to-r  from-[#F7D6D0] to-[#FFFFED]">
      <h2 className="text-3xl font-bold text-center mb-8">Trimester Chart</h2>
      <div className="container mx-auto">
        <div className="flex flex-col md:flex-row justify-between items-center">
          {/* 1st Trimester */}
          <div className="w-full md:w-1/3 p-4">
            <div className="bg-white rounded-lg shadow-lg p-6">
              <h3 className="text-xl font-bold mb-2">1st Trimester</h3>
              <div className="mb-4">
                <p className="text-gray-600">0-12 weeks</p>
                <div className="flex justify-between text-gray-400">
                  <span>0 kg</span>
                  <span>0.4 kg</span>
                  <span>2.0 kg</span>
                </div>
              </div>
              <div className="bg-gradient-to-r from-gray-300 to-green-400 h-3 rounded-full overflow-hidden">
                <div className="bg-green-500 h-full w-1/3"></div>
              </div>
            </div>
          </div>

          {/* 2nd Trimester */}
          <div className="w-full md:w-1/3 p-4">
            <div className="bg-white rounded-lg shadow-lg p-6">
              <h3 className="text-xl font-bold mb-2">2nd Trimester</h3>
              <div className="mb-4">
                <p className="text-gray-600">13-26 weeks</p>
                <div className="flex justify-between text-gray-400">
                  <span>2.0 kg</span>
                  <span>5.0 kg</span>
                  <span>8.0 kg</span>
                </div>
              </div>
              <div className="bg-gradient-to-r from-gray-300 to-blue-400 h-3 rounded-full overflow-hidden">
                <div className="bg-blue-500 h-full w-1/2"></div>
              </div>
            </div>
          </div>

          {/* 3rd Trimester */}
          <div className="w-full md:w-1/3 p-4">
            <div className="bg-white rounded-lg shadow-lg p-6">
              <h3 className="text-xl font-bold mb-2">3rd Trimester</h3>
              <div className="mb-4">
                <p className="text-gray-600">27-40 weeks</p>
                <div className="flex justify-between text-gray-400">
                  <span>8.0 kg</span>
                  <span>10.8 kg</span>
                  <span>13.6 kg</span>
                </div>
              </div>
              <div className="bg-gradient-to-r from-gray-300 to-yellow-400 h-3 rounded-full overflow-hidden">
                <div className="bg-yellow-500 h-full w-2/3"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TrimesterChart;
