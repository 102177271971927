import React from 'react';

const Treatments = () => {
  return (
    <section className="p-8 ">
      <h2 className="text-3xl font-bold text-center mb-8">Our Treatments</h2>
      <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
        {/* Gynecology */}
        <div className="relative overflow-hidden rounded-lg shadow-lg">
          <img
            src="https://images.unsplash.com/photo-1654931800911-7a9cfb3b7c17?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8R3luZWNvbG9neXxlbnwwfHwwfHx8MA%3D%3D"
            alt="Gynecology"
            className="w-full h-64 object-cover"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-transparent to-black opacity-0 hover:opacity-90 transition-opacity duration-300 flex items-center justify-center">
            <h3 className="text-2xl font-bold text-white text-center">
              Gynecology
            </h3>
          </div>
        </div>

        {/* Obstetrics */}
        <div className="relative overflow-hidden rounded-lg shadow-lg">
          <img
            src="https://images.unsplash.com/photo-1606572733585-1c54ba3cf917?q=80&w=1935&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            alt="Obstetrics"
            className="w-full h-64 object-cover"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-transparent to-black opacity-0 hover:opacity-90 transition-opacity duration-300 flex items-center justify-center">
            <h3 className="text-2xl font-bold text-white text-center">
              Obstetrics
            </h3>
          </div>
        </div>

        {/* Surgeries */}
        <div className="relative overflow-hidden rounded-lg shadow-lg">
          <img
            src="https://images.unsplash.com/photo-1579154491915-611e891d3a5b?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            alt="Surgeries"
            className="w-full h-64 object-cover"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-transparent to-black opacity-0 hover:opacity-90 transition-opacity duration-300 flex items-center justify-center">
            <h3 className="text-2xl font-bold text-white text-center">
              Surgeries
            </h3>
          </div>
        </div>

        {/* Infertility */}
        <div className="relative overflow-hidden rounded-lg shadow-lg">
          <img
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS7_XlNdJhNJaIa7WvPzqvfRiVEItLa1F8Jhw&s"
            alt="Infertility"
            className="w-full h-64 object-cover"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-transparent to-black opacity-0 hover:opacity-90 transition-opacity duration-300 flex items-center justify-center">
            <h3 className="text-2xl font-bold text-white text-center">
              Infertility
            </h3>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Treatments;
