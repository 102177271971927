import logo from './logo.svg';
import './App.css';
import Routing from './Routing/Routing';
import { BrowserRouter } from 'react-router-dom';
import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

function App() {
  useEffect(() => {
    AOS.init({
      duration: 2000, // Duration of the animation in milliseconds
    });
  }, []);
  return (
    <div className="bg-gradient-to-r  from-[#FFFFED] to-[#F7D6D0]  ">
      <BrowserRouter>
        <Routing />
      </BrowserRouter>
    </div>
  );
}

export default App;
