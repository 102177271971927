import React from 'react';
import { Link } from 'react-router-dom';
import {
  FaLocationArrow,
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
  FaWhatsapp,
  FaEnvelope,
  FaPhone,
} from 'react-icons/fa';
import { CiLocationOn } from 'react-icons/ci';
import { FaLocationDot } from 'react-icons/fa6';

const Footer = () => {
  return (
    <footer className="bg-primary text-white py-8 px-5">
      <div className="container mx-auto">
        <div className="flex flex-wrap justify-between">
          {/* Links Section */}
          <div className="w-full md:w-1/2 lg:w-1/4 mb-8 ">
            <h3 className="text-lg font-bold mb-4 ml-5">About</h3>
            <ul>
              <li>
                <Link
                  to="/about"
                  className="block px-4 py-2 hover:text-yellow-500 transition duration-300"
                >
                  Dr. Meera Soni
                </Link>
              </li>
              <li>
                <Link
                  to="/photo-gallery"
                  className="block px-4 py-2 hover:text-yellow-500 transition duration-300"
                >
                  Photo Gallery
                </Link>
              </li>
              <li>
                <Link
                  to="/testimonials"
                  className="block px-4 py-2 hover:text-yellow-500 transition duration-300"
                >
                  Testimonials
                </Link>
              </li>
            </ul>
          </div>

          {/* Department Links */}
          <div className="w-full md:w-1/2 lg:w-1/4 mb-8">
            <h3 className="text-lg font-bold mb-4 ml-5">Department</h3>
            <ul>
              <li>
                <Link
                  to="/pregnancy-care"
                  className="block px-4 py-2 hover:text-yellow-500 transition duration-300"
                >
                  Pregnancy Care
                </Link>
              </li>
              <li>
                <Link
                  to="/gynaecology"
                  className="block px-4 py-2 hover:text-yellow-500 transition duration-300"
                >
                  Gynaecology
                </Link>
              </li>
              <li>
                <Link
                  to="/infertility"
                  className="block px-4 py-2 hover:text-yellow-500 transition duration-300"
                >
                  Infertility
                </Link>
              </li>
              <li>
                <Link
                  to="/painless-normal-delivery"
                  className="block px-4 py-2 hover:text-yellow-500 transition duration-300"
                >
                  Painless Normal Delivery
                </Link>
              </li>
              <li>
                <Link
                  to="/cesarean-delivery"
                  className="block px-4 py-2 hover:text-yellow-500 transition duration-300"
                >
                  Cesarean Delivery
                </Link>
              </li>
              <li>
                <Link
                  to="/high-risk-pregnancy"
                  className="block px-4 py-2 hover:text-yellow-500 transition duration-300"
                >
                  High Risk Pregnancy
                </Link>
              </li>
            </ul>
          </div>
          {/* Department Links */}
          <div className="w-full md:w-1/2 lg:w-1/4 mb-8">
            <h3 className="text-lg font-bold mb-4 ml-5">Department</h3>
            <ul>
              <li>
                <Link
                  to="/hysterectomy"
                  className="block px-4 py-2 hover:text-yellow-500 transition duration-300"
                >
                  Hysterectomy
                </Link>
              </li>
              <li>
                <Link
                  to="/myomectomy"
                  className="block px-4 py-2 hover:text-yellow-500 transition duration-300"
                >
                  Myomectomy
                </Link>
              </li>
              <li>
                <Link
                  to="/ovarian-cystectomy"
                  className="block px-4 py-2 hover:text-yellow-500 transition duration-300"
                >
                  Ovarian Cystectomy
                </Link>
              </li>
              <li>
                <Link
                  to="/operative-laparoscopy"
                  className="block px-4 py-2 hover:text-yellow-500 transition duration-300"
                >
                  Operative Laparoscopy
                </Link>
              </li>
              <li>
                <Link
                  to="/tubectomy"
                  className="block px-4 py-2 hover:text-yellow-500 transition duration-300"
                >
                  Tubectomy
                </Link>
              </li>
            </ul>
          </div>

          {/* Contact Us Link */}
          <div className="w-full md:w-1/2 lg:w-1/4 mb-8 ">
            <h3 className="text-lg font-bold mb-4">Contact Us</h3>

            <div className="flex items-center mb-2">
              <FaPhone className="mr-2" />
              <a
                target="_blank"
                href="tel:+1234567890"
                className="hover:text-yellow-500 transition duration-300"
                rel="noreferrer"
              >
                +917803940379
              </a>
            </div>
            <div className="flex items-center mb-2">
              <FaPhone className="mr-2" />
              <a
                target="_blank"
                href="tel:+0987654321"
                className="hover:text-yellow-500 transition duration-300"
                rel="noreferrer"
              >
                +917803940379
              </a>
            </div>
            <div className="flex items-center mb-2">
              <FaEnvelope className="mr-2" />
              <a
                target="_blank"
                href="mailto:info@example.com"
                className="hover:text-yellow-500 transition duration-300"
                rel="noreferrer"
              >
                info@example.com
              </a>
            </div>
            <div className="flex items-center mb-2">
              <FaLocationDot className="mr-2" />
              <a
                target="_blank"
                href="mailto:info@example.com"
                className="hover:text-yellow-500 transition duration-300"
                rel="noreferrer"
              >
                Ashoka Medi Hub, Aranya, opposite Bawri Hanuman Mandir, Sector
                D, Slice 1, Part II, Scheme 78, Vijay Nagar, Indore, Madhya
                Pradesh 452010
              </a>
            </div>
            <div className="flex items-center mb-2">
              <FaWhatsapp className="mr-2" />
              <a
                target="_blank"
                href="https://wa.me/7803940379"
                className="hover:text-yellow-500 transition duration-300"
                rel="noreferrer"
              >
                WhatsApp Us
              </a>
            </div>
          </div>
        </div>

        {/* Social Media Section */}
        <div className="flex justify-center space-x-6 mt-8">
          <a
            href="https://www.facebook.com"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-yellow-500 transition duration-300"
          >
            <FaFacebookF />
          </a>
          <a
            href="https://www.twitter.com"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-yellow-500 transition duration-300"
          >
            <FaTwitter />
          </a>
          <a
            href="https://www.instagram.com"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-yellow-500 transition duration-300"
          >
            <FaInstagram />
          </a>
          <a
            href="https://www.linkedin.com"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-yellow-500 transition duration-300"
          >
            <FaLinkedinIn />
          </a>
        </div>

        {/* Copyright Section */}
        <div className="text-center mt-8">
          <p className="text-sm">
            &copy; 2024{' '}
            <Link
              to="https://r4healthcare.in/"
              target="_blank"
              rel="noopener"
              className="underline underline-offset-4 text-yellow font-bold"
            >
              R4 HealthCare
            </Link>{' '}
            . All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
