import React from 'react';

const PainlessNormalDelivery = () => {
  return (
    <div className="p-6 bg-white shadow-lg rounded-lg max-w-4xl mx-auto my-8">
      <h1 className="text-3xl font-bold text-center mb-6">
        Dr. Meera Soni Obstetrics and Gynecology
      </h1>
      <h2 className="text-2xl font-semibold text-center mb-4">
        Your Partner in Women's Health
      </h2>

      <div className="mb-6 text-center">
        <img
          src="https://www.drnamratagupta.com/images/services/normal-delivery.jpg"
          alt="Painless Normal Delivery"
          className="w-full h-auto rounded-md shadow-md mb-4"
        />
      </div>

      <p className="mb-6">
        <h3 className="text-xl font-semibold mb-4">
          {' '}
          PAINLESS NORMAL DELIVERY{' '}
        </h3>
        Becoming a mother is a wonderful journey. However, a woman experiences
        intense pain during the time of childbirth. In case it is their first
        pregnancy, the pain becomes unbearable for most of the women. However,
        with revolutionary advancements nowadays women get the option to opt for
        painless methods. Painless delivery is such a method that can help to
        reduce the pain during childbirth & enables women to enjoy it as well.
        Epidural is a type of anesthesia that is used to give relief from pain
        during labor. A large number of women prefer this procedure after
        knowing the benefits it provides.
      </p>

      <h3 className="text-xl font-semibold mb-4">Contact Us</h3>
      <p>
        If you have any questions or would like to schedule an appointment,
        please contact us at <strong>7803940379</strong>. We look forward to
        partnering with you on your journey to health and wellness.
      </p>
    </div>
  );
};

export default PainlessNormalDelivery;
