import React from 'react';

const OperativeLaparoscopy = () => {
  return (
    <div className="p-6 bg-white shadow-lg rounded-lg max-w-4xl mx-auto my-8">
      <h1 className="text-3xl font-bold text-center mb-6">Dr. Meera Soni Obstetrics and Gynecology</h1>
      <h2 className="text-2xl font-semibold text-center mb-4">Your Partner in Women's Health</h2>
      
      <div className="mb-6 text-center">
        <img src="https://www.rmany.com/media/pages/treatment-options/operative-laparoscopy/d4382ef7c0-1666141848/treatment-operative_laparoscopy.png" alt="Operative Laparoscopy" className="w-full h-auto rounded-md shadow-md mb-4"/>
      </div>

      <p className="mb-6">
        At Dr. Meera Soni Obstetrics and Gynecology, we specialize in operative laparoscopy to diagnose and treat gynecological conditions with minimal invasiveness. Our team utilizes advanced techniques to ensure optimal outcomes for our patients.
      </p>

      <h3 className="text-xl font-semibold mb-4">Operative Laparoscopy</h3>
      <p>
        Laparoscopy, also known as minimally invasive surgery or keyhole surgery, is a surgical technique used to perform various procedures through small incisions in the abdomen. It involves inserting a thin, flexible tube called a laparoscope through one of the incisions. The laparoscope has a camera and light source attached to it, allowing the surgeon to visualize the inside of the abdomen on a monitor in real-time.
      </p>

      <h4 className="text-lg font-semibold mb-3">Key Points about Laparoscopy</h4>
      <ul className="list-disc list-inside mb-6">
        <li>Surgical Approach: Laparoscopy offers several advantages over traditional open surgery, including smaller incisions, reduced blood loss, less postoperative pain, faster recovery times, and shorter hospital stays. It is commonly used for both diagnostic and therapeutic purposes across various surgical specialties.</li>
        <li>Diagnostic Laparoscopy: In diagnostic laparoscopy, the surgeon uses the laparoscope to visually inspect the abdominal organs to diagnose or evaluate conditions such as endometriosis, pelvic inflammatory disease, ovarian cysts, or abdominal trauma.</li>
        <li>Therapeutic Laparoscopy: In therapeutic laparoscopy, surgical instruments are inserted through additional small incisions to perform procedures such as removal of ovarian cysts or fibroids, treatment of endometriosis, or repair of pelvic organ prolapse.</li>
        <li>Procedure: During laparoscopy, the abdomen is inflated with carbon dioxide gas to create space. The surgeon then inserts the laparoscope and surgical instruments through small incisions and performs the necessary steps while observing on a monitor. After the procedure, the gas is released, and incisions are closed.</li>
        <li>Recovery and Risks: Recovery time is shorter compared to open surgery, with most patients resuming normal activities within days to weeks. Risks include bleeding, infection, injury to organs, and anesthesia-related complications.</li>
      </ul>

      <h3 className="text-xl font-semibold mb-4">Contact Us</h3>
      <p>
        If you have any questions or would like to schedule an appointment, please contact us at <strong>7803940379</strong>. We look forward to partnering with you on your journey to health and wellness.
      </p>
    </div>
  );
}

export default OperativeLaparoscopy;
