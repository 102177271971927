import React from 'react';

const Hysterectomy = () => {
  return (
    <div className="p-6 bg-white shadow-lg rounded-lg max-w-4xl mx-auto my-8">
      <h1 className="text-3xl font-bold text-center mb-6">
        Dr. Meera Soni Obstetrics and Gynecology
      </h1>
      <h2 className="text-2xl font-semibold text-center mb-4">
        Your Partner in Women's Health
      </h2>

      <div className="mb-6 text-center">
        <img
          src="https://d2yjegym0lbr1w.cloudfront.net/thumbs/hysterectomyoverview_1280.jpg?v=202003107"
          alt="Hysterectomy"
          className="w-full h-auto rounded-md shadow-md mb-4"
        />
      </div>

      <p className="mb-6">
        At Dr. Meera Soni Obstetrics and Gynecology, we specialize in
        hysterectomy procedures, which involve the surgical removal of the
        uterus. This procedure may be recommended for various conditions,
        including uterine fibroids, endometriosis, or cancer.
      </p>

      <h3 className="text-xl font-semibold mb-4">Hysterectomy Overview</h3>
      <p className="mb-6">
        A hysterectomy is the surgical removal of the uterus and may involve
        removing surrounding organs and tissues, such as the fallopian tubes and
        ovaries, depending on the reason for surgery. After a hysterectomy,
        pregnancy is not possible, and menstrual periods cease.
      </p>

      <h3 className="text-xl font-semibold mb-4">Types of Hysterectomy</h3>
      <ul className="list-disc list-inside mb-6">
        <li>
          <strong>Total Hysterectomy:</strong> Removal of the uterus and cervix,
          leaving the ovaries intact.
        </li>
        <li>
          <strong>Subtotal or Partial Hysterectomy:</strong> Removal of the
          upper part of the uterus, leaving the cervix intact.
        </li>
        <li>
          <strong>
            Total Hysterectomy with Bilateral Salpingo-Oophorectomy:
          </strong>{' '}
          Removal of the uterus, cervix, fallopian tubes (salpingectomy), and
          ovaries (oophorectomy).
        </li>
        <li>
          <strong>
            Radical Hysterectomy with Bilateral Salpingo-Oophorectomy:
          </strong>{' '}
          Removal of the uterus, cervix, fallopian tubes, ovaries, upper portion
          of the vagina, and surrounding tissues, performed for certain cancers.
        </li>
      </ul>

      <h3 className="text-xl font-semibold mb-4">Reasons for Hysterectomy</h3>
      <ul className="list-disc list-inside mb-6">
        <li>Uterine Fibroids</li>
        <li>Endometriosis</li>
        <li>Uterine Prolapse</li>
        <li>Abnormal Uterine Bleeding</li>
        <li>Gynecologic Cancer</li>
      </ul>

      <h3 className="text-xl font-semibold mb-4">
        Types of Surgical Approaches
      </h3>
      <ul className="list-disc list-inside mb-6">
        <li>Abdominal Hysterectomy</li>
        <li>Vaginal Hysterectomy</li>
        <li>Laparoscopic Hysterectomy</li>
        <li>Robot-Assisted Hysterectomy</li>
      </ul>

      <p className="mb-6">
        The choice of surgical approach depends on factors such as the reason
        for the hysterectomy, medical history, and surgeon's expertise. It's
        essential to discuss options and potential risks with your healthcare
        provider.
      </p>

      <h3 className="text-xl font-semibold mb-4">Contact Us</h3>
      <p>
        If you have any questions or would like to schedule an appointment,
        please contact us at <strong>7803940379</strong>. Our team at Dr. Meera
        Soni Obstetrics and Gynecology looks forward to assisting you with your
        health and wellness needs.
      </p>
    </div>
  );
};

export default Hysterectomy;
