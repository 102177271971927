import React from 'react';
import Banner from './Banner';
import Treatments from './Treatments';
import ContactForm from './ContactForm';
import DoctorInfo from '../Aboutuse/DoctorInfo';
import WhyChooseUs from '../WhyChooseUs/WhyChooseUs';
import TrimesterChart from '../TrimesterChart/TrimesterChart';

const Hero = () => {
  return (
    <div>
      <Banner />
      <DoctorInfo />
      <Treatments />
      <WhyChooseUs />
      <TrimesterChart />
      <ContactForm />
    </div>
  );
};

export default Hero;
