import React from 'react';

const Infertility = () => {
  return (
    <div className="p-6 bg-white shadow-lg rounded-lg max-w-4xl mx-auto my-8">
      <h1 className="text-3xl font-bold text-center mb-6">
        Dr. Meera Soni Obstetrics and Gynecology
      </h1>
      <h2 className="text-2xl font-semibold text-center mb-4">
        Your Partner in Women's Health
      </h2>

      <div className="mb-6 text-center">
        <img
          src="https://media.cnn.com/api/v1/images/stellar/prod/150421212330-02-questions-on-infertility.jpg?q=w_4400,h_2475,x_0,y_0,c_fill"
          alt="Infertility"
          className="w-full h-auto rounded-md shadow-md mb-4"
        />
      </div>

      <p className="mb-6">
        <h3 className="text-xl font-semibold mb-4">Infertility</h3>
        Infertility, the inability to become pregnant can be treated through
        IVF, ICSI, IUI, at an affordable cost in Indore. Get in touch with Dr.
        Meera Soni now. Infertility is a condition where you can’t get pregnant
        after one year of trying to conceive. Causes of infertility can include
        ovulatory disorders, endometriosis, low sperm count or low testosterone.
        The risk of infertility increases as you age. Many treatment options are
        available for people with infertility.
      </p>

      <h3 className="text-xl font-semibold mb-4">Contact Us</h3>
      <p>
        If you have any questions or would like to schedule an appointment,
        please contact us at <strong>7803940379</strong>. We look forward to
        partnering with you on your journey to health and wellness.
      </p>
    </div>
  );
};

export default Infertility;
