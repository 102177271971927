import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { FaLocationArrow, FaPhone } from 'react-icons/fa';
import './Topbar.css';
const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <nav className="bg-primary fixed top-0 w-full z-50 text-white pb-2">
        {/* top bar  */}
        <div className=" marquee">
          <div className="marquee-content flex justify-between text-sm ">
            <div className="mr-10">
              Ashoka Medi Hub, Aranya, opposite Bawri Hanuman Mandir, Sector D,
              Slice 1, Part II, Scheme 78, Vijay Nagar, Indore, Madhya Pradesh
              452010
            </div>
            <div className="flex items-center ">
              <FaPhone className="mr-2" />
              <span className="">Appointments: Call 7803940379</span>
            </div>
          </div>
        </div>
        {/* top bar  */}

        <div className="container mx-auto flex md:flex-row flex-col justify-between   items-center rounded-2xl p-3 bg-white text-black">
          <div className="flex justify-around  gap-10 items-center">
            <Link to="/">
              <div className=" text-2xl font-bold">Dr. Meera Soni</div>
            </Link>
            <div className="md:hidden">
              <button onClick={() => setIsOpen(!isOpen)}>
                {isOpen ? (
                  <XIcon className="w-6 h-6" />
                ) : (
                  <MenuIcon className="w-6 h-6" />
                )}
              </button>
            </div>
          </div>
          <div
            className={`md:flex ${
              isOpen ? 'block' : 'hidden'
            } w-full md:w-auto`}
          >
            <div
              className={`md:flex ${
                isOpen ? 'block' : 'hidden'
              } md:flex-row flex-col w-full md:w-auto`}
            >
              <NavLink
                to="/"
                exact
                className="px-4 py-2 md:py-0 inline-block"
                activeClassName="text-yellow border-b-2 border-yellow"
              >
                Home
              </NavLink>
              {/* About Dropdown */}
              <div className="relative group">
                <button className="px-4 py-2 md:py-0 inline-block">
                  About
                </button>
                <div className="hidden text-white absolute group-hover:block group-hover:border-t-2 group-hover:border-yellow bg-primary mt-1 py-10 rounded shadow-lg z-50 mx-auto">
                  <div className="container mx-auto">
                    <div className="flex justify-start flex-col whitespace-nowrap">
                      <NavLink
                        to="/about"
                        className="block px-4 py-2 md:py-0 inline-flex items-center hover:text-yellow"
                        activeClassName="text-yellow"
                      >
                        <FaLocationArrow className="text-sm mt-1 mr-2" />
                        Dr.Meera Soni
                      </NavLink>
                      <NavLink
                        to="/photo-gallery"
                        className="block px-4 py-2 md:py-0 inline-flex items-center hover:text-yellow"
                        activeClassName="text-yellow"
                      >
                        <FaLocationArrow className="text-sm mt-1 mr-2" />
                        Photo Gallery
                      </NavLink>
                      <NavLink
                        to="/testimonials"
                        className="block px-4 py-2 md:py-0 inline-flex items-center hover:text-yellow"
                        activeClassName="text-yellow"
                      >
                        <FaLocationArrow className="text-sm mt-1 mr-2" />
                        Testimonials
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>

              {/* Department Dropdown */}
              <div className="relative group">
                <button className="px-4 py-2 md:py-0 inline-block">
                  Department
                </button>
                <div className="hidden text-white absolute group-hover:block group-hover:border-t-2 group-hover:border-yellow bg-primary mt-1 py-10 rounded shadow-lg z-50 mx-auto">
                  <div className="container mx-auto">
                    <div className="flex justify-center flex-col whitespace-nowrap">
                      <NavLink
                        to="/pregnancy-care"
                        className="block px-4 py-2 md:py-0 inline-flex items-center hover:text-yellow"
                        activeClassName="text-yellow"
                      >
                        <FaLocationArrow className="text-sm mt-1 mr-2" />
                        Pregnancy Care
                      </NavLink>
                      <NavLink
                        to="/gynaecology"
                        className="block px-4 py-2 md:py-0 inline-flex items-center hover:text-yellow"
                        activeClassName="text-yellow"
                      >
                        <FaLocationArrow className="text-sm mt-1 mr-2" />
                        Gynaecology
                      </NavLink>
                      <NavLink
                        to="/infertility"
                        className="block px-4 py-2 md:py-0 inline-flex items-center hover:text-yellow"
                        activeClassName="text-yellow"
                      >
                        <FaLocationArrow className="text-sm mt-1 mr-2" />
                        Infertility
                      </NavLink>
                      <NavLink
                        to="/painless-normal-delivery"
                        className="block px-4 py-2 md:py-0 inline-flex items-center hover:text-yellow"
                        activeClassName="text-yellow"
                      >
                        <FaLocationArrow className="text-sm mt-1 mr-2" />
                        Painless Normal Delivery
                      </NavLink>
                      <NavLink
                        to="/cesarian-delivery"
                        className="block px-4 py-2 md:py-0 inline-flex items-center hover:text-yellow"
                        activeClassName="text-yellow"
                      >
                        <FaLocationArrow className="text-sm mt-1 mr-2" />
                        Cesarian Delivery
                      </NavLink>
                      <NavLink
                        to="/high-risk-pregnancy"
                        className="block px-4 py-2 md:py-0 inline-flex items-center hover:text-yellow"
                        activeClassName="text-yellow"
                      >
                        <FaLocationArrow className="text-sm mt-1 mr-2" />
                        High Risk Pregnancy
                      </NavLink>
                      <NavLink
                        to="/hyteractomy"
                        className="block px-4 py-2 md:py-0 inline-flex items-center hover:text-yellow"
                        activeClassName="text-yellow"
                      >
                        <FaLocationArrow className="text-sm mt-1 mr-2" />
                        Hyteractomy
                      </NavLink>
                      <NavLink
                        to="/myomectomy"
                        className="block px-4 py-2 md:py-0 inline-flex items-center hover:text-yellow"
                        activeClassName="text-yellow"
                      >
                        <FaLocationArrow className="text-sm mt-1 mr-2" />
                        Myomectomy
                      </NavLink>
                      <NavLink
                        to="/overian-cystectomy"
                        className="block px-4 py-2 md:py-0 inline-flex items-center hover:text-yellow"
                        activeClassName="text-yellow"
                      >
                        <FaLocationArrow className="text-sm mt-1 mr-2" />
                        Overian Cystectomy
                      </NavLink>
                      <NavLink
                        to="/operative-laparoscopy"
                        className="block px-4 py-2 md:py-0 inline-flex items-center hover:text-yellow"
                        activeClassName="text-yellow"
                      >
                        <FaLocationArrow className="text-sm mt-1 mr-2" />
                        Operative Laparoscopy
                      </NavLink>
                      <NavLink
                        to="/tubectomy"
                        className="block px-4 py-2 md:py-0 inline-flex items-center hover:text-yellow"
                        activeClassName="text-yellow"
                      >
                        <FaLocationArrow className="text-sm mt-1 mr-2" />
                        Tubectomy
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>

              {/* Contact Us Link */}
              <NavLink
                to="/contact"
                className="px-4 py-2 md:py-0 inline-block"
                activeClassName="text-yellow border-b-2 border-yellow"
              >
                Contact Us
              </NavLink>
            </div>
          </div>
          <div className="md:block hidden ">
            <div className="flex items-center ">
              <FaPhone className="mr-2" />
              <span className="text-sm">+917803940379</span>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
