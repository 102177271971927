import React from 'react';

const Myomectomy = () => {
  return (
    <div className="p-6 bg-white shadow-lg rounded-lg max-w-4xl mx-auto my-8">
      <h1 className="text-3xl font-bold text-center mb-6">Dr. Meera Soni Obstetrics and Gynecology</h1>
      <h2 className="text-2xl font-semibold text-center mb-4">Your Partner in Women's Health</h2>
      
      <div className="mb-6 text-center">
        <img src="https://cdn-assets-eu.frontify.com/s3/frontify-enterprise-files-eu/eyJvYXV0aCI6eyJjbGllbnRfaWQiOiJmcm9udGlmeS1leHBsb3JlciJ9LCJwYXRoIjoiaWhoLWhlYWx0aGNhcmUtYmVyaGFkXC9hY2NvdW50c1wvYzNcLzQwMDA2MjRcL3Byb2plY3RzXC8yMDlcL2Fzc2V0c1wvMzNcLzM4OTI3XC84ZTQyMjNmNGVhYzk5NGI0MTE3NDM3NzQyM2Y3NGU5MC0xNjU4MzAyNzA5LmpwZyJ9:ihh-healthcare-berhad:_yTclknibPOrLwWnU_nOlH7Mouul98AEZ9i13T00GXg?format=webp" alt="Myomectomy" className="w-full h-auto rounded-md shadow-md mb-4"/>
      </div>

      <p className="mb-6">
        At Dr. Meera Soni Obstetrics and Gynecology, we specialize in myomectomy procedures to remove uterine fibroids while preserving fertility. Our team provides personalized care to ensure the best outcomes for our patients.
      </p>

      <h3 className="text-xl font-semibold mb-4">Myomectomy Overview</h3>
      <p className="mb-6">
        Myomectomy is a surgical procedure used to remove uterine fibroids, which are non-cancerous growths that can cause symptoms such as pelvic pain, heavy periods, and pressure on nearby organs. Unlike hysterectomy, myomectomy preserves the uterus and is an option for women who wish to retain their fertility.
      </p>

      <h3 className="text-xl font-semibold mb-4">Types of Myomectomy</h3>
      <ul className="list-disc list-inside mb-6">
        <li><strong>Abdominal Myomectomy:</strong> Surgical removal through an open incision in the lower belly.</li>
        <li><strong>Laparoscopic Myomectomy:</strong> Minimally invasive surgery using small incisions and a camera for guidance.</li>
        <li><strong>Hysteroscopic Myomectomy:</strong> Removal through a hysteroscope inserted through the vagina and cervix for fibroids within the uterine cavity.</li>
      </ul>

      <p className="mb-6">
        Each type of myomectomy offers benefits in terms of recovery time and invasiveness, tailored to individual patient needs and fibroid characteristics.
      </p>

      <h3 className="text-xl font-semibold mb-4">Contact Us</h3>
      <p>
        If you have any questions or would like to schedule an appointment, please contact us at <strong>7803940379</strong>. We look forward to partnering with you on your journey to health and wellness.
      </p>
    </div>
  );
}

export default Myomectomy;
