// src/components/Banner.js
import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Banner = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <div className="banner z-0">
      <Slider {...settings}>
        <div>
          <img
            src="https://images.unsplash.com/photo-1706065264583-55f1a8549769?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            alt="Banner 1"
            className="w-full md:h-[500px] h-full object-cover"
          />
        </div>
        <div>
          <img
            src="https://images.unsplash.com/photo-1691934338591-9575aa800288?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            alt="Banner 2"
            className="w-full md:h-[500px] h-full object-cover"
          />
        </div>
        <div>
          <img
            src="https://images.unsplash.com/photo-1691934338662-2ce7f3134f36?q=80&w=1925&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            alt="Banner 3"
            className="w-full md:h-[500px] h-full object-cover"
          />
        </div>
      </Slider>
    </div>
  );
};

export default Banner;
