import React from 'react';

const HighRiskPregnancy = () => {
  return (
    <div className="p-6 bg-white shadow-lg rounded-lg max-w-4xl mx-auto my-8">
      <h1 className="text-3xl font-bold text-center mb-6">Dr. Meera Soni Obstetrics and Gynecology</h1>
      <h2 className="text-2xl font-semibold text-center mb-4">Your Partner in Women's Health</h2>
      
      <div className="mb-6 text-center">
        <img src="https://www.drshivanjalinayak.com/wp-content/uploads/2022/10/High-Risk-Pregnancy-Dr-Shivanjali-Nayak-Gynacologist-Kolkata-2.jpg" alt="High Risk Pregnancy" className="w-full h-auto rounded-md shadow-md mb-4"/>
      </div>

      <p className="mb-6">
        At Dr. Meera Soni Obstetrics and Gynecology, we offer specialized care for high-risk pregnancies. Our team is equipped to manage and monitor conditions that may complicate pregnancy, ensuring the best possible outcomes for both mother and baby.
      </p>

      <h3 className="text-xl font-semibold mb-4">Contact Us</h3>
      <p>
        If you have any questions or would like to schedule an appointment, please contact us at <strong>7803940379</strong>. We look forward to partnering with you on your journey to health and wellness.
      </p>
    </div>
  );
}

export default HighRiskPregnancy;
