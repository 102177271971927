import React from 'react';
import { Route, Router, Routes } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

import Contact from '../components/Contact';
import Hero from '../pages/Home/Hero';
import PhotoGallery from '../pages/Aboutuse/PhotoGallery';
import Testimonials from '../pages/Aboutuse/Testimonials';
import About from '../pages/Aboutuse/About';
import PregnancyCare from '../pages/Department/PregnancyCare';
import Infertility from '../pages/Department/Infertility';
import PainlessNormalDelivery from '../pages/Department/PainlessNormalDelivery';
import CesarianDelivery from '../pages/Department/CesarianDelivery';
import HighRiskPregnancy from '../pages/Department/HighRiskPregnancy';
import Myomectomy from '../pages/Department/Myomectomy';
import OvarianCystectomy from '../pages/Department/OvarianCystectomy';
import OperativeLaparoscopy from '../pages/Department/OperativeLaparoscopy';
import Tubectomy from '../pages/Department/Tubectomy';
import Hysterectomy from '../pages/Department/Hysterectomy';
import Gynecology from '../pages/Department/Gynecology';

const Routing = () => {
  return (
    <div className="">
      <Navbar />
      <main className="py-20 ">
        <Routes>
          <Route path="/" element={<Hero />} />

          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<About />} />
          <Route path="/photo-gallery" element={<PhotoGallery />} />
          <Route path="/testimonials" element={<Testimonials />} />
          <Route path="/pregnancy-care" element={<PregnancyCare />} />

          <Route path="/gynaecology" element={<Gynecology />} />
          <Route path="/infertility" element={<Infertility />} />
          <Route
            path="/painless-normal-delivery"
            element={<PainlessNormalDelivery />}
          />
          <Route path="/cesarian-delivery" element={<CesarianDelivery />} />
          <Route path="/high-risk-pregnancy" element={<HighRiskPregnancy />} />
          <Route path="/hyteractomy" element={<Hysterectomy />} />
          <Route path="/myomectomy" element={<Myomectomy />} />
          <Route path="/overian-cystectomy" element={<OvarianCystectomy />} />
          <Route
            path="/operative-laparoscopy"
            element={<OperativeLaparoscopy />}
          />
          <Route path="/tubectomy" element={<Tubectomy />} />
        </Routes>
      </main>
      <Footer />
    </div>
  );
};

export default Routing;
