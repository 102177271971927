import React from 'react';

const Gynecology = () => {
  return (
    <div className="p-6 bg-white shadow-lg rounded-lg max-w-4xl mx-auto my-8">
      <h1 className="text-3xl font-bold text-center mb-6">
        Dr. Meera Soni Obstetrics and Gynecology
      </h1>
      <h2 className="text-2xl font-semibold text-center mb-4">
        Your Partner in Women's Health
      </h2>

      <div className="mb-6 text-center">
        <img
          src="https://www.drlevinobgyn.com/wp-content/uploads/sites/419/2023/04/istockphoto-1345109107-612x612-1.jpg"
          alt="Gynecology"
          className="w-full h-auto rounded-md shadow-md mb-4"
        />
      </div>

      <p className="mb-6">
        <strong>Comprehensive Women's Health Services</strong> <br />
        At Dr. Meera Soni , we provide comprehensive women's health services,
        including routine exams, screenings, and treatments for a range of
        conditions. Our team of experienced OB/GYNs is committed to providing
        personalized care to meet your unique needs.
      </p>
      <p className="mb-6">
        <strong> Gynecological exams and screenings</strong> <br />
        We offer routine gynecological exams and screenings including pap
        smears, breast exams, and HPV testing to ensure optimal reproductive
        health.
      </p>

      <h3 className="text-xl font-semibold mb-4">Contact Us</h3>
      <p>
        If you have any questions or would like to schedule an appointment,
        please contact us at <strong>7803940379</strong>. We look forward to
        partnering with you on your journey to health and wellness.
      </p>
    </div>
  );
};

export default Gynecology;
