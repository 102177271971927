import React from 'react';
import { FaLocationArrow } from 'react-icons/fa';

const About = () => {
  return (
    <div
      id="about"
      className="relative bg-white overflow-hidden mt-16 text-justify"
    >
      <div className="max-w-7xl mx-auto">
        <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
          <svg
            className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
            fill="currentColor"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            aria-hidden="true"
          >
            <polygon points="50,0 100,0 50,100 0,100"></polygon>
          </svg>

          <div className="pt-1"></div>

          <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
            <div className="sm:text-center lg:text-left">
              <h2 className="my-6 text-2xl tracking-tight font-extrabold text-gray-900 sm:text-3xl md:text-4xl">
                About me
              </h2>

              <p>
                Dr. Meera Soni, a compassionate obstetrician, and a
                patient-centered gynecologist is providing care and treatment
                for all stages of a women’s lifespan- Birth, Adolescence,
                Motherhood, Menopause, and Beyond. Dr. Meera Soni has completed
                her graduation from MGM Medical College, Indore and D.G.O. from
                MGM Medical College, Indore. She then pursued her
                Post-graduation in MS (Obs n Gynae) from the prestigious and
                world-renowned J.N. Medical College, AMU (UP). During her
                tenure, she worked under the National rural health mission
                (NRHM) in Government Medical officer at CHC, Khachrod (M.P).
              </p>

              <p>
                Dr. Meera Soni has learned and successfully executed varieties
                of obstetric and gynecological surgeries including cesarean
                sections, hysteroscopy, Vaginal hysterectomy,
                laparoscopic/Abdominal hysterectomy, and laparoscopic
                cystectomy. She has conducted more than 500 normal deliveries
                and specializes in providing painless deliveries, managing
                high-risk pregnancies such as preeclampsia, eclampsia,
                cholestasis of pregnancy, gestational diabetes, etc. She offers
                24*7 obstetric and gynecological emergency services.
              </p>

              <h3 className="mt-6 mb-3 text-xl font-semibold">Obstetrics</h3>
              <p>
                The spectrum of services provided covers the Antepartum,
                Intrapartum, and Postpartum areas. Antepartum Services include
                early detection and diagnosis of serious ailments of pregnancy,
                nutritional guidance, dietary counseling, and antenatal
                exercises facilitated by a physiotherapist for normal delivery.
              </p>

              <h3 className="mt-6 mb-3 text-xl font-semibold">
                High-Risk Delivery
              </h3>
              <p>
                We provide integrated care for managing high-risk pregnant
                patients with various medical conditions including but not
                limited to Preeclampsia & Eclampsia, severe anemia, heart
                disease, diabetes mellitus, kidney disease, liver disease,
                epilepsy, placenta praevia, previous surgeries (LSCS/Myomectomy/
                Ectopic), and twin pregnancies.
              </p>

              <h3 className="mt-6 mb-3 text-xl font-semibold">
                Painless Delivery
              </h3>
              <p>
                Our Intrapartum care includes Painless Labor & Delivery to
                alleviate anxiety and fear, promoting natural childbirth as a
                stress-free experience. Postpartum care includes breastfeeding
                guidance, perineal care, newborn care, immunization, newborn
                disease screening, and contraception advice.
              </p>

              <h3 className="mt-6 mb-3 text-xl font-semibold">
                Gynecology Procedures
              </h3>
              <p>
                Our center provides comprehensive surgical management of
                gynecological problems in state-of-the-art modular operation
                theatres. Procedures offered include Hysterectomy (Abdominal/
                Vaginal/ NDVH / Laparoscopy), Myomectomies (Open /
                Laparoscopic), Ovarian Cystectomies (Open / Laparoscopic),
                Diagnostic Laparoscopy/ Operative Laparoscopy, Hysteroscopic
                Tubal Cannulation, Sacropexy for Vault Prolapse, Tubal
                Recanalization, Tubectomies (Open / Laproscopic), Hysteropexy,
                Sling Surgeries, Surgery for Endometriosis, and Cervical
                Encerclage.
              </p>
            </div>
          </main>
        </div>
      </div>
      <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
        <img
          className="h-56 w-full object-cover object-top sm:h-72 md:h-96 lg:w-full lg:h-full"
          src="https://lh3.googleusercontent.com/p/AF1QipPxhaJupNs7Dk9ThPGO68squ3tKebqzMbd6ezOw=s1360-w1360-h1020"
          alt="zbc"
        />
      </div>
    </div>
  );
};

export default About;
