import React from 'react';

const PregnancyCare = () => {
  return (
    <div className="p-6 bg-white shadow-lg rounded-lg max-w-4xl mx-auto my-8">
      <h1 className="text-3xl font-bold text-center mb-6">
        Dr. Meera Soni Obstetrics and Gynecology
      </h1>
      <h2 className="text-2xl font-semibold text-center mb-4">
        Your Partner in Women's Health
      </h2>

      <div className="mb-6 text-center">
        <img
          src="https://bsmedia.business-standard.com/_media/bs/img/article/2023-04/01/full/1680325134-8682.jpg"
          alt="Clinic"
          className="w-full h-auto rounded-md shadow-md mb-4"
        />
      </div>

      <p className="mb-6">
        At Dr. Meera Soni Obstetrics and Gynecology, we understand that women
        have unique health needs that require specialized care. Our team of
        skilled physicians and healthcare professionals is here to provide you
        with the highest quality medical care in a supportive and compassionate
        environment. Whether you are in need of routine gynecological care,
        family planning services, or advanced obstetric care, we are here to
        help you achieve optimal health and wellness.
      </p>

      <h3 className="text-xl font-semibold mb-4">Our Services</h3>
      <ul className="list-disc list-inside mb-6">
        <li>
          Comprehensive Prenatal Care: From your first positive test through
          delivery and postpartum care, our team is with you every step of the
          way.
        </li>
        <li>
          High-Risk Pregnancy Management: Specialized care for pregnancies that
          require extra monitoring and support.
        </li>
        <li>
          Fertility Counseling and Treatments: Helping you understand your
          fertility options and providing advanced treatments to assist with
          conception.
        </li>
        <li>
          Routine Gynecological Exams: Annual exams, Pap smears, and other
          preventive care to keep you healthy.
        </li>
        <li>
          Menstrual Disorder Treatments: Expert care for menstrual
          irregularities, heavy bleeding, and other menstrual concerns.
        </li>
        <li>
          Menopause Management: Comprehensive care to help you manage symptoms
          and maintain health during menopause.
        </li>
        <li>
          Minimally Invasive Surgery: Advanced surgical options for
          gynecological conditions that minimize recovery time and discomfort.
        </li>
      </ul>

      <h3 className="text-xl font-semibold mb-4">Why Choose Us?</h3>
      <p className="mb-6">
        At Dr. Meera Soni Obstetrics and Gynecology, we are dedicated to
        providing personalized care tailored to your individual needs. Our
        clinic is equipped with state-of-the-art technology and staffed by a
        team of experts who are passionate about women's health. We believe in
        empowering our patients through education and support, ensuring that you
        have all the information you need to make informed decisions about your
        health.
      </p>

      <h3 className="text-xl font-semibold mb-4">Patient Testimonials</h3>
      <p className="mb-4 italic">
        "Dr. Meera Soni and her team provided exceptional care throughout my
        pregnancy. They were always available to answer my questions and made me
        feel comfortable and confident every step of the way." - Ananya P.
      </p>
      <p className="mb-6 italic">
        "I have been a patient of Dr. Meera Soni for over five years, and I can
        honestly say that she is the best gynecologist I have ever had. She is
        knowledgeable, compassionate, and truly cares about her patients." -
        Priya S.
      </p>

      <h3 className="text-xl font-semibold mb-4">Contact Us</h3>
      <p>
        If you have any questions or would like to schedule an appointment,
        please contact us at <strong>7803940379</strong>. We look forward to
        partnering with you on your journey to health and wellness.
      </p>
    </div>
  );
};

export default PregnancyCare;
