import React from 'react';

const Tubectomy = () => {
  return (
    <div className="p-6 bg-white shadow-lg rounded-lg max-w-4xl mx-auto my-8">
      <h1 className="text-3xl font-bold text-center mb-6">
        Dr. Meera Soni Obstetrics and Gynecology
      </h1>
      <h2 className="text-2xl font-semibold text-center mb-4">
        Your Partner in Women's Health
      </h2>

      <div className="mb-6 text-center">
        <img
          src="https://www.asterhospitals.in/sites/default/files/2023-04/Tubectomy%20-%20mobile.jpg"
          alt="Tubectomy"
          className="w-full h-auto rounded-md shadow-md mb-4"
        />
      </div>

      <p className="mb-6">
        At Dr. Meera Soni Obstetrics and Gynecology, we provide tubectomy
        procedures for patients seeking permanent birth control options. Our
        team ensures compassionate care and support throughout the process.
      </p>

      <h3 className="text-xl font-semibold mb-4">Contact Us</h3>
      <p>
        If you have any questions or would like to schedule an appointment,
        please contact us at <strong>7803940379</strong>. We look forward to
        partnering with you on your journey to health and wellness.
      </p>
    </div>
  );
};

export default Tubectomy;
