import React from 'react';
import { Link } from 'react-router-dom';

const DoctorInfo = () => {
  return (
    <section className="p-8 relative overflow-hidden text-white ">
      {/* Background element with rotated background */}
      <div className="absolute inset-0 bg-primary transform -skew-y-12 py-10 origin-top-left"></div>

      <div className="container mx-auto flex flex-col md:flex-row items-center relative z-10">
        {/* Content section */}
        <div className="md:w-1/2 md:ml-8 relative z-20">
          <div className="bg-primary py-6 px-8 rounded-lg shadow-lg transform -skew-y-12">
            <h2 className="text-3xl font-bold mb-4">
              Dr. Meera Soni, M.B.B.S (M.S)
            </h2>
            <p className="mb-4">
              Compassionate Obstetrician & Patient-Centered Gynecologist
            </p>
            <p className="mb-2">
              Providing care and treatment for all stages of a woman's lifespan
              - Birth, Adolescence, Motherhood, Menopause, and Beyond.
            </p>
            <p className="mb-2">
              Completed her graduation from MGM Medical College, Indore and
              D.G.O. from MGM Medical College, Indore.
            </p>
            <p className="mb-2">
              Post-graduation in MS (Obs & Gynae) from the prestigious and
              world-renowned J.N. Medical College, AMU (UP).
            </p>
            <Link to="/about">
              <div className="text-center border-2 border-yellow p-2 hover:bg-yellow rounded-md">
                Know More
              </div>
            </Link>
          </div>
        </div>

        {/* Image section */}
        <div className="md:w-1/2 relative z-20">
          <div className="overflow-hidden rounded-lg shadow-lg transform skew-y-12">
            <img
              src="https://lh3.googleusercontent.com/p/AF1QipPxhaJupNs7Dk9ThPGO68squ3tKebqzMbd6ezOw=s1360-w1360-h1020"
              alt="Dr. Arpana Jain"
              className="object-cover h-1/2"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default DoctorInfo;
