import React from 'react';
import { motion } from 'framer-motion';

const Contact = () => {
  return (
    <motion.section
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="p-8 relative overflow-hidden  mt-16 py-20 bg-primary bg-opacity-40"
    >
      {/* Background element with rotated background */}

      <div className="container mx-auto flex flex-col gap-5 md:flex-row items-center relative z-10">
        {/* Content section */}
        <div className="md:w-1/2 text-white md:ml-8 relative z-20">
          <div className="bg-primary shado py-6 px-8 rounded-lg  transform md:-skew-y-12 -skew-y-6">
            <h2 className="text-3xl font-bold mb-4 text-center ">
              Fix an Appointment
            </h2>
            <form className="space-y-4">
              <div>
                <label className="block text-gray-700">Name</label>
                <input
                  type="text"
                  className="w-full p-3 border border-gray-300 rounded"
                />
              </div>
              <div>
                <label className="block text-gray-700">Email</label>
                <input
                  type="email"
                  className="w-full p-3 border border-gray-300 rounded"
                />
              </div>
              <div>
                <label className="block text-gray-700">Phone</label>
                <input
                  type="tel"
                  className="w-full p-3 border border-gray-300 rounded"
                />
              </div>
              <div>
                <label className="block text-gray-700">Message</label>
                <textarea className="w-full p-3 border border-gray-300 rounded"></textarea>
              </div>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                type="submit"
                className="hover:bg-yellow border-yellow border-t-4 border-2 bg-opacity-50 text-white px-4 py-3 rounded w-full shadow-md hover:bg-yellow-600 transition duration-300"
              >
                Check Availability
              </motion.button>
            </form>
          </div>
        </div>{' '}
        {/* Image section */}
        <div className=" relative z-20">
          <div className="overflow-hidden rounded-lg shadow-lg transform md:skew-y-12 -skew-y-6">
            <img
              src="https://s3.envato.com/files/424017044/2504895.jpg"
              alt="Dr. Arpana Jain"
              className="md:h-[75vh] h-full "
            />
          </div>
        </div>
      </div>
      <div className="my-10">
        <h1 className="text-3xl font-bold mb-4 text-center">
          Welcome to Our Location
        </h1>

        <div className=" px-5 py-4 bg-gray-100 justify-center">
          <map name="location-map " className="w-full">
            {/* Add your map iframe or image here */}
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14715.820767555508!2d75.8894655!3d22.7670441!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3963031f0aa706ff%3A0x281eb2284e0a8ecd!2sDr.%20Meera%20Soni%20-%20Best%20Obs.%20%26%20Gynecologist%20in%20indore%20%7C%20Laparoscopic%20Surgeon%20in%20indore%20%7C%20Infertility%20Specialist%20in%20indore!5e0!3m2!1sen!2sin!4v1722250579744!5m2!1sen!2sin"
              width="100%"
              height="400"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </map>
        </div>
      </div>
    </motion.section>
  );
};

export default Contact;
