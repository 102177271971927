import React from 'react';

const OvarianCystectomy = () => {
  return (
    <div className="p-6 bg-white shadow-lg rounded-lg max-w-4xl mx-auto my-8">
      <h1 className="text-3xl font-bold text-center mb-6">
        Dr. Meera Soni Obstetrics and Gynecology
      </h1>
      <h2 className="text-2xl font-semibold text-center mb-4">
        Your Partner in Women's Health
      </h2>

      <div className="mb-6 text-center">
        <img
          src="https://www.yashodahealthcare.com/blogs/wp-content/uploads/2023/04/Ovarian-Cystectomy.jpg"
          alt="Ovarian Cystectomy"
          className="w-full h-auto rounded-md shadow-md mb-4"
        />
      </div>

      <p className="mb-6">
        At Dr. Meera Soni Obstetrics and Gynecology, we specialize in ovarian
        cystectomy procedures to remove ovarian cysts and ensure the health and
        well-being of our patients. Our team provides expert care tailored to
        each patient's needs.
      </p>

      <h3 className="text-xl font-semibold mb-4">Ovarian Cystectomy Overview</h3>
      <p className="mb-6">
        Ovarian cystectomy is a surgical procedure to remove ovarian cysts that
        may cause symptoms or complications. It is performed with the goal of
        preserving ovarian function whenever possible.
      </p>

      <h3 className="text-xl font-semibold mb-4">Types of Ovarian Cystectomy</h3>
      <ul className="list-disc list-inside mb-6">
        <li>
          <strong>Laparoscopic Ovarian Cystectomy:</strong> Minimally invasive
          surgery using small incisions and a camera (laparoscope) for guidance.
        </li>
        <li>
          <strong>Open Ovarian Cystectomy (Laparotomy):</strong> Surgical
          removal through a larger incision in the abdomen, suitable for larger
          cysts or specific medical conditions.
        </li>
      </ul>

      <p className="mb-6">
        Dr. Meera Soni prefers laparoscopic ovarian cystectomy due to its
        minimally invasive nature, faster recovery time, and reduced risk of
        complications compared to open surgery.
      </p>

      <h3 className="text-xl font-semibold mb-4">Contact Us</h3>
      <p>
        If you have any questions or would like to schedule an appointment,
        please contact us at <strong>7803940379</strong>. We look forward to
        partnering with you on your journey to health and wellness.
      </p>
    </div>
  );
};

export default OvarianCystectomy;
