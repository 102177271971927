import React from 'react';

const CesarianDelivery = () => {
  return (
    <div className="p-6 bg-white shadow-lg rounded-lg max-w-4xl mx-auto my-8">
      <h1 className="text-3xl font-bold text-center mb-6">Dr. Meera Soni Obstetrics and Gynecology</h1>
      <h2 className="text-2xl font-semibold text-center mb-4">Your Partner in Women's Health</h2>
      
      <div className="mb-6 text-center">
        <img src="https://images.agoramedia.com/wte3.0/gcms/c-section-birth-2020-722x406.jpg" alt="Cesarian Delivery" className="w-full h-auto rounded-md shadow-md mb-4"/>
      </div>

      <p className="mb-6">
        At Dr. Meera Soni Obstetrics and Gynecology, we provide expert cesarean delivery services for patients who require or prefer a surgical birth. Our team ensures a safe and supportive environment for both mother and baby.
      </p>

      <h3 className="text-xl font-semibold mb-4">Why Are C-Sections Needed?</h3>
      <p className="mb-6">
        A cesarean delivery, often referred to as a C-section, is a surgical procedure used to deliver a baby when a vaginal delivery is not safe or possible. It involves making an incision in the abdomen and uterus to remove the baby.
      </p>

      <p className="mb-6">
        Some C-sections are scheduled if the doctor knows that a vaginal birth would be risky, such as when the baby is in breech or transverse position, or if the mother has certain medical conditions.
      </p>

      <p className="mb-6">
        Other C-sections are unexpected emergency deliveries done when problems arise with the mother and/or baby during pregnancy or labor, such as labor complications or placental issues.
      </p>

      <h3 className="text-xl font-semibold mb-4">Reasons for Cesarian Delivery</h3>
      <ul className="list-disc list-inside mb-6">
        <li>Fetal Distress: If the baby shows signs of distress, like abnormal heart rate.</li>
        <li>Breech Presentation: When the baby isn't head-down near delivery time.</li>
        <li>Placenta Previa: When the placenta covers the cervix.</li>
        <li>Previous C-section: Due to risks of uterine rupture.</li>
        <li>Maternal Health Conditions: Such as high blood pressure or active genital herpes.</li>
        <li>Multiple Births: Safety reasons or if the first baby isn't in position.</li>
      </ul>

      <p className="mb-6">
        It's essential for expecting parents to discuss their birth preferences and any concerns with their healthcare provider well before labor begins. Whether planned in advance or due to unforeseen complications, our goal is always the safety and well-being of both mother and baby.
      </p>

      <h3 className="text-xl font-semibold mb-4">Contact Us</h3>
      <p>
        If you have any questions or would like to schedule an appointment, please contact us at <strong>7803940379</strong>. We look forward to partnering with you on your journey to health and wellness.
      </p>
    </div>
  );
}

export default CesarianDelivery;
